import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import React, { useRef, useState } from "react";
import "./App.scss";
import DefaultPage from "./pages/default";
import {
  RINGBA_STORAGE_KEYS,
  sessionStorageKeys,
  useEventID,
  useGeneratorQuery,
  useRingba,
} from "wecall-config-lib";
import { useEffect } from "react";
import arrow from "../src/assets/arrow.svg";
import axios from "axios";
import QuizLander, {
  CTAQuizLander,
  CongratsQuizLander,
  IncomeQuizLander,
  MedicareMedicaidQuizLander,
} from "./pages/quiz";
import { PropagateLoader } from "react-spinners";
import card from "../src/assets/card-in-hand.svg";
import { useInitRingba } from "wecall-config-lib";
import Privacy from "./pages/Privacy";
import TermsAndCondition from "./pages/Terms";
import heroimage from "../src/assets/heroImage.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";

window._rgba_tags = window._rgba_tags || [];

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

export const ROUTES = {
  congrats: "congrats",
  income: "10k",
  medicareMedicaid: "consolidate",
  healthInsurance: "health-insurance",
  phoneNumber: "phone-number",
  notQualified: "not-qualified",
};

function GetRingbaNumber({ setNumber }) {
  const [ringbaUser, setRingbaUser] = useState(
    window.domain_settings.rinbaUser
  );
  const testRingba =
    window.domain_settings.rinbaUser.default ||
    window.domain_settings.rinbaUser;

  const { number } = useInitRingba({
    ringbaKey: {
      key: testRingba.key,
      number: testRingba.number,
      user: testRingba.user,
    },
  });
  useEffect(() => {
    setNumber(number);
  }, [number]);
  return <></>;
}

function Congrats({ headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();
  const [number, setNumber] = useState();

  const generatorQuery = useGeneratorQuery();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.congrats, true);
  }, []);

  return (
    <>
      <GetRingbaNumber setNumber={setNumber} />

      <div className="left-side">
        <div className="congrats">
          <h2>{headline}</h2>
          <p>
            You’re prequalified for the program to forgive credit card payments,
            medical bills, personal loans and more. Tap the button below to call
            and claim.
          </p>
          <a
            onClick={triggerContactEvent}
            href={
              "tel:" +
              (window.pnumber || window.domain_settings.rinbaUser?.number)
            }
            className="call_now"
          >
            {window.pnumber || window.domain_settings.rinbaUser?.number}
          </a>
          <p>
            This program has extremely limited capacity, so call us in the next{" "}
            <Timer /> to claim your spot before it's given to another applicant
            in line.
          </p>
          <img src={"/assets/trust-seal.png"} loading="lazy" alt=""></img>
        </div>
      </div>
    </>
  );
}

function NotQualified({}) {
  return (
    <>
      <div className="left-side">
        <div className="congrats">
          <h1>Sorry</h1>
          <h2>{"You are not qualified."}</h2>
        </div>
      </div>
    </>
  );
}

function MedicareMedicaid({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const navigate = useNavigate();

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
    sessionStorage.setItem("consolidate", value);

    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["consolidate"]: value });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.medicareMedicaid, true);
  }, []);
  return (
    <>
      {/* <div className="headline-wrapper">{headline}</div> */}
      <div className="left-side">
        <div className="question">
          <h2 className="question-headline">
            Want to know if you qualify for more than $10,000 in debt relief?
            Tap Yes!{" "}
          </h2>

          <Link
            onClick={() => setValues("yes")}
            id="medicare_medicaid_yes"
            // to={{
            //   pathname: "../" + ROUTES.notQualified,
            //   search: generatorQuery.get(),
            // }}
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            Yes
          </Link>

          <Link
            onClick={() => setValues("no")}
            id="medicare_medicaid_no"
            // to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
            // to={{
            //   pathname: "../" + ROUTES.notQualified,
            //   search: generatorQuery.get(),
            // }}
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

function Income({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    // storeRgbaData(RINGBA_STORAGE_KEYS.income, value);
    sessionStorage.setItem("10k", value);

    window._rgba_tags = window._rgba_tags || [];
    // window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.income]: value });
    window._rgba_tags.push({ ["10k"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      // return navigate({ pathname: "../", search: generatorQuery.get() });
      sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">{headline}</div>
      <div className="para-wrapper">
        <p>
          If you owe more than $10,000, you are automatically prequalified for
          the debt forgiveness program.
        </p>
        {/* <p>
          This program has been used by thousands of people to significantly
          reduce their debt on credit cards, medical bills, personal loans.
        </p> */}
        <p>
          You can qualify, it's{" "}
          <span className="para-highlighted">very simple</span> {""} and the
          call is {""}
          <span className="para-highlighted">totally FREE.</span>
        </p>
        {/* <p>
          All you have to do is answer the following questions and speak with
          one of our experts to request debt relief.
        </p> */}
      </div>
      <div className="right-side">
        <img src={heroimage}></img>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>How much debt you have?</h2>

          <Link
            onClick={() => {
              setValues("yes");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            Under $10,000
          </Link>
          <Link
            onClick={() => {
              setValues("no");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            Above $10,000
          </Link>
        </div>
      </div>
    </>
  );
}
const SignupSchema = Yup.object().shape({
  zipCode: Yup.string()
    .matches(/^[0-9]{5}$/, "Zip code must be 5 digits")
    .required("Zip code is required"),
});
function ZipCode({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const formik = useFormik({
    initialValues: {
      zipCode: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      console.log("zipcode", values);
    },
    validateOnChange: true,
    validateOnBlur: true,
  });
  const handleGetStarted = async (event) => {
    event.preventDefault();
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
      navigate("/10k");
    } else {
      formik.setTouched(
        Object.keys(formik.values).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">{headline}</div>
      <div className="para-wrapper">
        <p>
          If you owe more than $10,000, you are automatically prequalified for
          the debt forgiveness program.
        </p>

        <p>
          You can qualify, it's{" "}
          <span className="para-highlighted">very simple</span> {""} and the
          call is {""}
          <span className="para-highlighted">totally FREE.</span>
        </p>
      </div>
      <div className="right-side">
        <img src={heroimage}></img>
      </div>
      <form>
        <div className="left-side">
          <div className="question">
            <h2>Please enter your zip code</h2>

            <input
              id="zipCode"
              type="text"
              name="zipCode"
              className="input-text"
              placeholder="Zip Code"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.zipCode && formik.errors.zipCode ? (
              <div className="error-message border-red">
                {formik.errors.zipCode}
              </div>
            ) : null}
            <Link
              className="button"
              type="submit"
              name="apply"
              value="Get Started"
              onClick={handleGetStarted}
            >
              Next
            </Link>
          </div>
        </div>
      </form>
    </>
  );
}

function HealthInsurance({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);

    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["healthInsurance"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">{headline}</div>
      <div className="left-side">
        <div className="question">
          <h2>Do you have over $10,000 in credit card debt?</h2>
          <Link
            onClick={() => setValues("yes")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            Yes
          </Link>
          <Link
            onClick={() => setValues("no")}
            // to={{
            //   pathname: nextPageRoute,
            //   search: generatorQuery.get(),
            // }}
            to={"https://rtrackt.com/clickout/23041/311260/?"}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

function PhoneNumber({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    // window._rgba_tags = window._rgba_tags || [];
    // window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.phoneNumber]: value });
  };

  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      setError(false);
      setLoading(true);
      setSubmitted(true);
      setApiErrorMessage("");

      const { data } = await axios.get(
        "https://wecalllongfunnel.improveourcredit.com?phone=" + phoneNumber
      );
      if (data.valid === false) {
        setError(true);
        if (data.message) {
          setApiErrorMessage(data.message);
        }
      } else {
        sessionStorage.setItem(RINGBA_STORAGE_KEYS.mobile, phoneNumber);
        window._rgba_tags = window._rgba_tags || [];
        window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.mobile]: phoneNumber });
        navigate(nextPageRoute);
      }
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (event) => {
    if (event.target.value.length > 14) return;

    setSubmitted(false);
    const value = event.target.value;

    const rawValue = value.replace(/\D/g, "");

    let formatted = rawValue;
    if (rawValue.length > 3 && rawValue.length <= 6) {
      formatted = `${rawValue.replace(/^(\d{3})(\d+)/g, "($1) $2")}`;
    } else if (rawValue.length > 6) {
      formatted = `${rawValue.replace(/^(\d{3})(\d{3})(\d+)/g, "($1) $2-$3")}`;
    }

    setFormattedPhoneNumber(formatted);
    setPhoneNumber(rawValue);
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);

  const validator = () => {
    if (error) return "Phone number is not valid.";
    if (submitted && formattedPhoneNumber.length < 14)
      return "Please enter 10 digit mobile number.";
  };
  return (
    <>
      <div className="headline-wrapper">{headline}</div>
      <div className="left-side">
        <div className="question">
          <h2>Enter your phone number</h2>
          <input
            type="text"
            placeholder="Phone Number"
            onChange={onChange}
            value={formattedPhoneNumber}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
          <em className="error">{apiErrorMessage || validator()}</em>
          <a
            href="javascript:void(0)"
            onClick={onSubmit}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            {loading ? (
              <PropagateLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "33.5px",
                }}
                color={"#fff"}
                size={10}
              />
            ) : (
              "Submit"
            )}
          </a>
        </div>
      </div>
    </>
  );
}

function CTA({ headline, subtitle }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    // generatorQuery.set(window.location.search);
    sessionStorage.setItem(routes.lander, true);
  }, []);
  return (
    <>
      <div className="span-headline-wrapper">
        <h3>
          <span>2024 Debt Reduction Program</span>
        </h3>
      </div>
      <div className="headline-wrapper">{headline}</div>
      <div className="sub-hero-section">
        <div className="left-side">
          <p>{subtitle}</p>

          <div className="desktop-cta">
            <Link
              id="cta-click"
              to={{ pathname: "10k", search: generatorQuery.get() }}
            >
              I Want My Debt Removed
              <img src={"/assets/arrow.svg"} alt=""></img>
            </Link>
            <span>37 Available Slots Left, Act Now!</span>
          </div>
        </div>

        <div className="right-side">
          <img src={card} alt=""></img>
        </div>
      </div>
    </>
  );
}

function App() {
  const [number, setNumber] = React.useState("");
  const [subsidyAmount, setSubsidyAmount] = React.useState("$6,400");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramSubsidyAmount = urlParams.get("subsidyAmount");

    if (paramSubsidyAmount) {
      setSubsidyAmount(paramSubsidyAmount);
      sessionStorage.setItem("subsidyAmount", paramSubsidyAmount);
    } else {
      setSubsidyAmount(sessionStorage.getItem("subsidyAmount") || "$6,400");
    }
  }, []);
  const eventID = useEventID();
  const generatorQuery = useGeneratorQuery();

  const [cityStateResponse, setStateCityResponse] = useState({
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const cityAddress = async () => {
    const options = {};
    try {
      const { data } = await axios.get(
        "https://funnel.improveourcredit.com/ip?key=askdjaslkdjaskjdsla"
      );
      const state = data.subdivisions[0].isoCode;
      const city = data.city.names.en;
      const country = data.country.names.en;
      const postalCode = data.postal.code;

      localStorage.setItem(sessionStorageKeys.zip, postalCode);
      localStorage.setItem(sessionStorageKeys.city, city);
      localStorage.setItem(sessionStorageKeys.state, state);
      setStateCityResponse({ state, city, country, zip: postalCode });

      window._rgba_tags = window._rgba_tags || [];
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.zip]: postalCode });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.city]: city });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.state]: state });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    eventID.getEventId();
    cityAddress();
    generatorQuery.set(window.location.search);

    const doc = window.document.getElementById("spin-loader");
    if (doc) {
      doc.remove();
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <DefaultPage
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <ZipCode
                nextPageRoute={"../" + ROUTES.income}
                headline={
                  <h1>
                    Reduce Debts Exceeding $10,000 Dollars with This {}
                    <span className="highlighted">Forgiveness Program.</span>
                    Find out if you qualify.
                    {}
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  <h1>
                    Reduce Debts Exceeding $10,000 Dollars with This {}
                    <span className="highlighted">Forgiveness Program.</span>
                    Find out if you qualify.
                    {}
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={
                  <h1>
                    New debt reduction plan for Americans, qualifying
                    individuals receive an average debt cancellation of {}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Congrats
                headline={`Congratulations!!
    `}
                number={number}
              />
            }
          />
          <Route path={ROUTES.notQualified} element={<NotQualified />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TermsAndCondition />} />
        </Route>

        <Route
          path="/es"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTAQuizLander
                subtitle={`Estadounidenses en ${cityStateResponse.city}, ${cityStateResponse.state}, con $10,000 o más en deuda de tarjeta de crédito tienen una tasa de aprobación del 95% - el financiamiento termina pronto.`}
                headline={
                  <h1>
                    Nuevo plan de reducción de deudas para estadounidenses, las
                    personas que califican reciben una cancelación de deuda
                    promedio de {""}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <IncomeQuizLander
                setNumber={setNumber}
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  <h1>
                    New debt reduction plan for Americans, qualifying
                    individuals receive an average debt cancellation of {}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaidQuizLander
                nextPageRoute={"../congrats"}
                headline={
                  <h1>
                    New debt reduction plan for Americans, qualifying
                    individuals receive an average debt cancellation of {}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <CongratsQuizLander headline={`¡Felicidades!`} number={number} />
            }
          />
          <Route path={ROUTES.notQualified} element={<NotQualified />} />
        </Route>

        <Route
          path="/lander-2"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTAQuizLander
                subtitle={`Americans in ${cityStateResponse.city}, ${cityStateResponse.state}, have only 3 days to claim their FREE ${subsidyAmount}* Subsidy which they can use for Grocery, Rent, Gas and More!`}
                headline={
                  <h1>
                    New debt reduction plan for Americans who qualify receive an
                    average debt reduction of{" "}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <IncomeQuizLander
                setNumber={setNumber}
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  <h1>
                    New debt reduction plan for Americans who qualify receive an
                    average debt reduction of{" "}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaidQuizLander
                nextPageRoute={"../congrats"}
                headline={
                  <h1>
                    New debt reduction plan for Americans who qualify receive an
                    average debt reduction of{" "}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <CongratsQuizLander
                headline={
                  "Congratulations! You are eligible for ${subsidyAmount} Subsidy."
                }
                number={number}
              />
            }
          />
          <Route path={ROUTES.notQualified} element={<NotQualified />} />
        </Route>

        <Route
          path="/lander-78"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTAQuizLander
                subtitle={`Americans in ${cityStateResponse.city}, ${cityStateResponse.state}, have only 3 days to claim their FREE ${subsidyAmount}* Subsidy which they can use for Grocery, Rent, Gas and More!`}
                headline={
                  "New debt reduction plan for Americans, qualifying individuals receive an average debt cancellation of $26,352."
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <IncomeQuizLander
                setNumber={setNumber}
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "New debt reduction plan for Americans, qualifying individuals receive an average debt cancellation of $26,352."
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaidQuizLander
                nextPageRoute={"../" + ROUTES.healthInsurance}
                headline={
                  "New debt reduction plan for Americans, qualifying individuals receive an average debt cancellation of $26,352."
                }
              />
            }
          />
          <Route
            path={ROUTES.healthInsurance}
            element={
              <HealthInsurance
                nextPageRoute={"../" + ROUTES.phoneNumber}
                headline={
                  "New debt reduction plan for Americans, qualifying individuals receive an average debt cancellation of $26,352."
                }
              />
            }
          />
          <Route
            path={ROUTES.phoneNumber}
            element={
              <PhoneNumber
                nextPageRoute={"../congrats"}
                headline={
                  "New debt reduction plan for Americans, qualifying individuals receive an average debt cancellation of $26,352."
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <CongratsQuizLander
                headline={
                  "Congratulations! You are eligible for ${subsidyAmount} Subsidy."
                }
                number={number}
              />
            }
          />
          <Route path={ROUTES.notQualified} element={<NotQualified />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export const Timer = () => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:10");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 190);
    return deadline;
  };
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return <span>{timer}</span>;
};

function Intiator() {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const fetchDomainSetting = async () => {
    const routes = ["es", "lander-2", "lander-78"];
    const pathname = window.location.pathname.split("/")[1];
    const finalizedPath = routes.find((route) => pathname.includes(route));
    const subDomain = window.location.hostname;
    const url = `https://backend.subsidysupportnetwork.org/sites/production?landerName=${
      finalizedPath ? finalizedPath : ""
    }&subDomainName=${subDomain}`;

    // const url = "https://backend.subsidyaid.com/sites/production?landerName=&subDomainName=testing.subsidyaid.com"
    if (window.location.hostname.includes("localhost")) {
      var script = window.document.createElement("script");
      script.src = "/scripts/localScript.js";
      script.async = false;
      document.head.appendChild(script);
    }

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        window.domain_settings = data;
        var script = window.document.createElement("script");
        script.src = "/scripts/scripts.js";
        script.async = false;
        document.head.appendChild(script);
        setScriptLoaded(true);
      })
      .catch((error) => {
        setScriptLoaded(true);
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  useEffect(() => {
    fetchDomainSetting();
  }, []);
  if (!scriptLoaded) return null;
  return <App />;
}

export default Intiator;
